import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductCarousel, DraggableSlider } from 'components';
import { useCalculator } from '../../hooks/calculator';
import generateClass from '../../utils/generateClass';
import '../../styles/globals.scss';
import styles from "./kite-quiver.module.scss";
import { Box, Card, CardContent, CardHeader, Divider, IconButton, Stack, Typography } from '@mui/material';


const lemonSqueezerIcon = require('../../assets/icons/lemon-squeezer.svg').default;

export const KiteQuiver = () : JSX.Element => {
  const stylePrefix = 'package-building-';
  const navigate = useNavigate();
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
  const {
    quiverTotalEquipmentMonthly,
    quiverRegularPrice,
    quiverTotalMonthly,
    quiverFeeMonthly,
  } = useCalculator();

  return (
    <>
      <div className={(generateClass(styles, stylePrefix, '-header'))}>
        <div className={(generateClass(styles, stylePrefix, '-header-icon-btn-left'))}>
          <div 
            style={{cursor: 'pointer', marginLeft: '5px'}} 
            onClick={() => { handleNavigation('/', {}) }}
          >
            <svg style={{width: '25px', height: '25px', color: 'rgb(0, 0, 0)'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16"> <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" fill="#000000"></path> </svg>
          </div>
        </div>
        <h2>Subscription Config.</h2>
        <div></div>
      </div>

      
      <div className={generateClass(styles, stylePrefix, '-info-container')}>
        <ProductCarousel />
        <div style={{marginTop: '20px', marginBottom: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Box style={{width: '80%', marginTop: '20px', marginBottom: '20px'}}>
            <DraggableSlider />
          </Box>
        </div>

        <Card sx={{ padding: 1, boxShadow: 3 }}>
          <CardHeader 
            title="Price summary"  
            sx={{ "& .MuiCardHeader-title": { fontWeight: "bold" }, padding: 1 }} />
          <CardContent>
            <Stack spacing={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography>Selected products</Typography>
                <Typography>€ {quiverTotalEquipmentMonthly}</Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Typography>+ Duration fee</Typography>
                <Typography>€ {quiverFeeMonthly}</Typography>
              </Stack>

              <Divider />

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">Total monthly:</Typography>
                <Typography variant="h6" color="primary">€ {quiverTotalMonthly}</Typography>
              </Stack>
            </Stack>

            <div className={styles.durationFee}>(incl. duration fee & VAT)</div>
            { quiverRegularPrice > 0 ?
              <div className={styles.priceContainer}>
                <div>These products would regulary cost you</div>
                <div className={styles.priceUnderline}></div>
                <div className={styles.regularPrice}>€ {quiverRegularPrice}</div>
              </div>
              : <></> 
            }
          </CardContent>
        </Card>
        
        <button className={styles.submitButton} onClick={() => { handleNavigation('/contactDetails', {}) }}>
          <div className={styles.baseDiv}>
            <div className={styles.nameDiv}>
              <img
                className={styles.iconLemonSqeezer}
                alt=""
                src={lemonSqueezerIcon}
              />
              <div className={styles.requestMyGear}>REQUEST MY GEAR</div>
            </div>
          </div>
        </button>
      </div>
    </>
  )
};