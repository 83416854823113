import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useFirebaseCMS } from '../hooks/firebaseCMS';
import { useCalculator, useCalculatorActions } from '../hooks/calculator';
import './draggable-slider.scss';
import { FirebaseFees } from 'interfaces';

export const DraggableSlider = () => {

  const { fees } = useFirebaseCMS();
  const [ monthSliderMin, setMonthSliderMin ] = useState(1);
  const [ monthSliderMax, setMonthSliderMax ] = useState(1);
  const { quiverMonthSelection } = useCalculator();
  const { setCurrentFee, setCurrentMonthsSelection } = useCalculatorActions();
  const [ monthStartValue ] = useState(quiverMonthSelection != null ? quiverMonthSelection : monthSliderMax);
  const [ customMarks, setCustomMarks ] = useState([] as {value: number, label: string}[]);
  const [ value, setValue ] = useState<number>(monthStartValue);


  useEffect(() => {
    const keys = Object.keys(fees);
    if (keys.length > 0){
      const minValue = Math.min(...keys.map((str) => Number(str)));
      setMonthSliderMin(minValue);

      const maxValue = Math.max(...keys.map((str) => Number(str)));
      setMonthSliderMax(maxValue);
    }

    setCustomMarks(getSliderMarksFromFees(fees));
  }, [fees])

  useEffect(() => {
    setCurrentFee(fees[value]);
  }, [fees, value, setCurrentFee])

  function getSliderMarksFromFees(fees: FirebaseFees) {
    const keys = Object.keys(fees);
    const marks: {value: number, label: string}[] = [];

    keys.forEach(key => {
      marks.push({value: Number(key), label: ''});
    });

    return marks;
  }

  function valueLabelFormat(value: number) {
    if (value === 1)
      return "1 MONTH";
    
    return value.toString() + " MONTHS";
  }


  return (
    <Slider
      color="primary"
      aria-label="Month Selection"
      valueLabelDisplay="on"
      value={value}
      defaultValue={monthSliderMax}
      valueLabelFormat={valueLabelFormat}
      step={null}
      min={monthSliderMin}
      max={monthSliderMax}
      marks={customMarks}
      onChange={(event, newValue) => { 
        if (newValue !== value){
          setValue(newValue as number);
        }
      }}
      onChangeCommitted={() => setCurrentMonthsSelection(value)}
    />
  )
};