import React, { FunctionComponent, useEffect } from "react";
import styles from "./success-screen.module.scss";
import { useLocation, useNavigate } from 'react-router-dom';
import { useCalculatorActions } from "hooks/calculator";
const lemonIcon = require('../../assets/icons/lemon.svg').default;


export const SuccessScreen: FunctionComponent = () => {
  const location = useLocation();
  const openHubspot: boolean = location.state.context.openHubspot;
  const navigate = useNavigate();
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });

  const { removeAllItemsFromSelectedProducts, setCurrentMonthsSelection } = useCalculatorActions();

  useEffect(() => {
    if (location.pathname === "/success") {
      console.log("Navigated to /success, delete all products from kite quiver!");
      removeAllItemsFromSelectedProducts();
      setCurrentMonthsSelection(24);
    }
  }, [location.pathname]); // Runs only when the path changes

  useEffect(() => {
    if(openHubspot === true){
        const script = document.createElement('script');

        script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
    }
  }, [openHubspot]);

  return (
    <div className={styles.successScreenDiv}>
      <div className={styles.centeredContent}>
        <div className={styles.successHeader}> Thank you for your request!</div>
        
        { openHubspot == true ?
          <div className={styles.inputSection}>
              <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/dominic-steinhauer/calculator?embed=true"></div>
          </div>
          :
          <>
            <div className={styles.weAreCheckingYourRequestA}>
              We will review your request and come back to you shortly with an indicative offer.
            </div>
            
            <div className={styles.yourSaltyLemonTeam}>Your Salty Lemon Team</div>
            <img className={styles.lemonIcon} alt="" src={lemonIcon} />
          </>

        }
      </div>

      <div className={styles.bottomContainer}>
        <div className={styles.goBackLemonShake}>{`Go back & Lemon Shake`}</div>
        <button className={styles.button} onClick={() => { handleNavigation('/shoppingCart', { context: { type: null }}) }}>
          <div className={styles.baseDiv}>
            <div className={styles.backToWebsite}>Back To Shopping Cart</div>
          </div>
        </button>
      </div>
    </div>
  );
};