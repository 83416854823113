import { IWoocommerceProduct, IWoocommerceVariation } from "interfaces";
import { isNullOrWhitespace } from "helpers/productDetails";

export function findVariationForProductSelection(productVariations: IWoocommerceVariation[], currentProduct: IWoocommerceProduct) : IWoocommerceVariation | null {
    let foundProductVariation: IWoocommerceVariation | null = null;
    for (let i = 0; i < productVariations.length; i++) {
        let matchesSelectedOptions = true;
  
        if(!isNullOrWhitespace(productVariations[i].size) && !isNullOrWhitespace(currentProduct.selectedSize) &&
            productVariations[i].size.toLowerCase() !== currentProduct.selectedSize.toLowerCase()) {
          matchesSelectedOptions = false;
        }
        if(!isNullOrWhitespace(productVariations[i].color) && !isNullOrWhitespace(currentProduct.selectedColor) &&
          productVariations[i].color.toLowerCase() !== currentProduct.selectedColor.toLowerCase()) {
          matchesSelectedOptions = false;
        }
        if(!isNullOrWhitespace(productVariations[i].boardType) && !isNullOrWhitespace(currentProduct.selectedBoardType) &&
          productVariations[i].boardType.toLowerCase() !== currentProduct.selectedBoardType.toLowerCase()) {
          matchesSelectedOptions = false;
        }
        if(!isNullOrWhitespace(productVariations[i].lineLength) && !isNullOrWhitespace(currentProduct.selectedLineLength) &&
          productVariations[i].lineLength.toLowerCase() !== currentProduct.selectedLineLength.toLowerCase()) {
          matchesSelectedOptions = false;
        }
  
        if(matchesSelectedOptions)
          foundProductVariation = productVariations[i] as IWoocommerceVariation;
    }
    return foundProductVariation;
}