import React, { useState, useEffect, FunctionComponent } from "react";
import styles from "./product-detail-view.module.scss";
import { useNavigate, useLocation } from 'react-router-dom';
import { FeatureType, IWoocommerceProduct, ProductType } from "interfaces";
import { useCalculator, useCalculatorActions, useWooCommerce, useWooCommerceActions } from "hooks";
import { SelectableFeature } from "components";
import { CalculatorBottomBar } from "../../components/calculator-bottom-bar";
import { getDefaultColorOption, getDefaultLineLengthOption, getDefaultSizeOption, isNullOrWhitespace } from "helpers/productDetails";

const productDetailBackground = require("../../assets/icons/product-detail-background.svg").default;
const arrowBackIcon = require("../../assets/icons/arrow-back.svg").default;


export const ProductDetailView: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navFromPage: string = location.state.context.from as string;
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
  
  const { selectedProductsWithVariations } = useCalculator();
  const [productDetailView, setProductDetailView] = useState(location.state.context.productDetailView as IWoocommerceProduct);

  const { allProducts } = useWooCommerce();
  const { loadAllVariationsForProductById } = useWooCommerceActions();

  const [productDetailSizeOptions, setProductDetailSizeOptions] = useState([] as string[]);
  const [productDetailColorOptions, setProductDetailColorOptions] = useState([] as string[]);
  const [productDetailLineLengthOptions, setProductDetailLineLengthOptions] = useState([] as string[]);

  useEffect(() => {
    if (productDetailView.productVariations == null || productDetailView.productVariations.length === 0){
      loadAllVariationsForProductById(productDetailView.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAllVariationsForProductById, productDetailView]);

  
  useEffect(() => {
    var updatedProduct = allProducts.find(elem => elem.id === productDetailView.id);

    if (updatedProduct != null){
      let uniqueSizeOptions = [...new Set(updatedProduct.productVariations?.filter(prodVar => isNullOrWhitespace(prodVar.size) === false && prodVar.attributes.find(attr => attr.name.toLowerCase() === FeatureType.Size.toString())?.option).map(elem => elem.size.toLowerCase()))];
      setProductDetailSizeOptions(uniqueSizeOptions);

      let uniqueColorOptions = [...new Set(updatedProduct.productVariations?.filter(prodVar => isNullOrWhitespace(prodVar.color) === false && prodVar.size === (uniqueSizeOptions.includes(productDetailView.selectedSize) ? productDetailView.selectedSize : uniqueSizeOptions[0])).map(elem => elem.color.toLowerCase()))];
      setProductDetailColorOptions(uniqueColorOptions);

      let uniqueLineLengthOptions = [...new Set(updatedProduct.productVariations?.filter(prodVar => isNullOrWhitespace(prodVar.lineLength) === false && prodVar.attributes.find(attr => attr.name.toLowerCase() === FeatureType.LineLength.toString())?.option).map(elem => elem.lineLength.toLowerCase()))];
      setProductDetailLineLengthOptions(uniqueLineLengthOptions);

      updatedProduct.selectedSize = productDetailView.selectedSize;
      updatedProduct.selectedColor = productDetailView.selectedColor;
      updatedProduct.selectedLineLength = productDetailView.selectedLineLength;
      updatedProduct.selectedProductVariation = productDetailView.selectedProductVariation;
      updatedProduct.selectionId = productDetailView.selectionId;
      setProductDetailView(updatedProduct);
    }

  }, [allProducts, productDetailView]);

  const [isProductInQuiver] = useState(selectedProductsWithVariations.find((elem: IWoocommerceProduct) => 
    elem.id === productDetailView.id && 
    (elem.selectedProductVariation != null && 
      productDetailView.selectedProductVariation != null ? elem.selectedProductVariation.id === productDetailView.selectedProductVariation.id : false
    )) !== undefined);

  const {updateItemInSelectedProducts} = useCalculatorActions();

  const handleUpdateOfChosenFeature = (featureType: FeatureType, value: any) => {
    if(featureType === FeatureType.Size){
      productDetailView.selectedSize = value as string;
      let uniqueColorOptions = [...new Set(productDetailView.productVariations?.filter(prodVar => isNullOrWhitespace(prodVar.color) === false && prodVar.size === (productDetailSizeOptions.includes(productDetailView.selectedSize) ? productDetailView.selectedSize : productDetailSizeOptions[0])).map(elem => elem.color.toLowerCase()))];
      setProductDetailColorOptions(uniqueColorOptions);
    } else if(featureType === FeatureType.Color){
      productDetailView.selectedColor = value;
    } else if(featureType === FeatureType.BoardType){
      productDetailView.selectedBoardType = value;
    } else if(featureType === FeatureType.LineLength){
      productDetailView.selectedLineLength = value;
    }
    // update product only if it was already added to the shopping cart
    updateItemInSelectedProducts(productDetailView); 
  };



  return (
    <>
      <img className={styles.arrowBackIcon} alt="" src={arrowBackIcon} style={{cursor: 'pointer'}} onClick={() => { navFromPage === 'shoppingCart' ? handleNavigation('/shoppingCart', {}) : handleNavigation('/chooseProduct', { context: { type: productDetailView.productType } }) }} />
      <div className={styles.productViewDiv}>
        <div className={styles.heroBlockDiv}>
          <div className={styles.imageBlockDiv}>
            <img className={styles.backgroundIcon} alt="" src={productDetailBackground} />
            <div className={styles.imageDiv}>
              <div className={styles.baseDiv}>
                <img className={styles.image36Icon} alt="" src={productDetailView.images[0].src} />
              </div>
            </div>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div className={styles.copyTitleDiv} style={{marginTop: '50px'}}>
            <div className={styles.headerTitle}>{productDetailView.name}</div>
          </div>
        </div>

        <div style={{marginBottom: '116px'}}>
          { productDetailSizeOptions.length > 0 ?
            <div className={styles.featureContainer}>
              <div className={styles.flexboxFeatureHeader}>  
                <div className={styles.featureHeader}>Size</div>
              </div>
              <SelectableFeature
                options={productDetailSizeOptions}
                chosenProduct={productDetailView}
                defaultOption={getDefaultSizeOption(productDetailView, productDetailSizeOptions[0])}
                featureType={FeatureType.Size}
                updateFeatureOnChosenItem={handleUpdateOfChosenFeature}
              />
            </div> : <></>
          }

          { productDetailColorOptions.length > 0 ?
            <div className={styles.featureContainer}>
              <div className={styles.flexboxFeatureHeader}>
                <div className={styles.featureHeader}>Color</div>
              </div>
              <SelectableFeature
                options={productDetailColorOptions}
                chosenProduct={productDetailView}
                defaultOption={getDefaultColorOption(productDetailView, productDetailColorOptions[0])}
                featureType={FeatureType.Color}
                updateFeatureOnChosenItem={handleUpdateOfChosenFeature}
              />
            </div> : <></>
          }

          { productDetailView.productType === ProductType.Bar &&
            productDetailLineLengthOptions.length > 0 ?
            <div className={styles.featureContainer}>
              <div className={styles.flexboxFeatureHeader}>
                <div className={styles.featureHeader}>Line Length</div>
              </div>
              <SelectableFeature
                options={productDetailLineLengthOptions}
                chosenProduct={productDetailView}
                defaultOption={getDefaultLineLengthOption(productDetailView, productDetailLineLengthOptions[0])}
                featureType={FeatureType.LineLength}
                updateFeatureOnChosenItem={handleUpdateOfChosenFeature}
              />
            </div> : <></>
          }

          <div className={styles.featureContainer}>
            <div className={styles.flexboxFeatureHeader}>
              <div className={styles.featureHeader}>Description</div>
            </div>
            <div className={styles.productDescription}>
              <div dangerouslySetInnerHTML={{ __html: productDetailView.description }} />
            </div>
          </div>
        </div>

        <CalculatorBottomBar allProducts={allProducts} selectedProduct={productDetailView} isProductInQuiver={isProductInQuiver}/>
      </div>
    </>
  );
};