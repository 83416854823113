import { FeatureType, IWoocommerceProduct } from "interfaces";



export function isNullOrWhitespace(testString: string) : boolean {
    if (!testString || testString == null || typeof testString !== 'string' || testString.trim() === ''){
      return true;
    } else {
      return false;
    }
  }

const getDefaultOptionByAttribute = (item: IWoocommerceProduct, type: FeatureType): any => {
    var foundDefault = item.defaultAttributes.find(option => option.name.toLowerCase() === type.toString())
    if (foundDefault !== undefined){
      return foundDefault.option;
    }
    return null;
};  

export function getDefaultSizeOption(product: IWoocommerceProduct, defaultSizeOption: string): string {
    if (product.selectedSize != null)
        return product.selectedSize;

    let defaultValue = getDefaultOptionByAttribute(product, FeatureType.Size);
    if (defaultValue != null)
        return defaultValue;

    return defaultSizeOption ?? null;
};

export function getDefaultColorOption(product: IWoocommerceProduct, defaultColorOption: string): string {
    if (product.selectedColor != null)
        return product.selectedColor;

    let defaultValue = getDefaultOptionByAttribute(product, FeatureType.Color);
    if (defaultValue != null)
        return defaultValue;

    return defaultColorOption ?? null; 
};

export function getDefaultLineLengthOption(product: IWoocommerceProduct, defaultLineLengthOption: string): string {
    if (product.selectedLineLength != null)
        return product.selectedLineLength;

    let defaultValue = getDefaultOptionByAttribute(product, FeatureType.LineLength);
    if (defaultValue != null)
        return defaultValue;

    return defaultLineLengthOption ?? null;
};
