import React from "react";
import styles from "./calculator-bottom-bar.module.scss";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useCalculator, useCalculatorActions } from "hooks";
import { IWoocommerceProduct } from "interfaces";

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Badge, { badgeClasses } from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';


export const CalculatorBottomBar = (props: { allProducts: IWoocommerceProduct[]; selectedProduct: IWoocommerceProduct|undefined; isProductInQuiver: boolean; }) : JSX.Element => {
    const navigate = useNavigate();
    const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
    
    const {
        selectedProductsWithVariations,
        quiverTotalMonthly,
    } = useCalculator();

    const {
        addItemToSelectedProducts,
        removeItemFromSelectedProducts
    } = useCalculatorActions();

    const CartBadge = styled(Badge)`
        & .${badgeClasses.badge} {
            top: -12px;
            right: -6px;
        }
`   ;

    return (
        <div className={styles.bottomBarDiv}>
            <Stack direction="row" spacing={0} justifyContent="flex-end" alignItems="center" sx={{ height: '96px', marginRight: '25px'}}>
                { props.selectedProduct !== undefined ?
                    <div style={{cursor: 'pointer', marginRight: '25px'}}>
                    
                        { props.isProductInQuiver === false ?
                            <Button variant="contained" startIcon={<AddIcon />} color={"secondary"} sx={{ height: '48px', color: 'black' }} onClick={() => {
                                var foundWooProduct = props.allProducts.find((product: IWoocommerceProduct) => product.id === props.selectedProduct!.id);
                                if (foundWooProduct != null && foundWooProduct.productVariations != null && foundWooProduct.productVariations.length > 0){
                                    props.selectedProduct!.productVariations = foundWooProduct.productVariations;
                                }
                                addItemToSelectedProducts(props.selectedProduct!);
                                handleNavigation('/shoppingCart', {})
                            }}>
                                Add
                            </Button>
                        : 
                            <Button variant="outlined" startIcon={<DeleteIcon />} color={"error"} sx={{ height: '48px' }} onClick={() => {
                                removeItemFromSelectedProducts(props.selectedProduct!.id, props.selectedProduct!.selectionId);
                                handleNavigation('/shoppingCart', {});
                            }}>
                                Delete
                            </Button>
                        }
                    
                    </div>
                : <></> }
                    <Button variant="contained" sx={{height: '48px', fontSize: '16px', color: 'white', textTransform: 'lowercase'}} onClick={() => {handleNavigation('/shoppingCart', { context: { type: null } })}}>
                        {quiverTotalMonthly} € monthly
                        <IconButton>
                            <ShoppingCartIcon fontSize="small" />
                            <CartBadge badgeContent={selectedProductsWithVariations.length} color="error" overlap="circular" />
                        </IconButton>
                    </Button>
            </Stack>
        </div>
    );
}