import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as calculatorActions, selectors as calculatorSelectors } from '../store/calculator';
import { IWoocommerceProduct } from 'interfaces';
import { useAppSelector } from './custom-redux-types';


const useCalculatorActions = () => {
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(
      {
        addItemToSelectedProducts: calculatorActions.addItemToSelectedProducts,
        updateItemInSelectedProducts: calculatorActions.updateItemInSelectedProducts,
        removeItemFromSelectedProducts: calculatorActions.removeItemFromSelectedProducts,
        removeAllItemsFromSelectedProducts: calculatorActions.removeAllItemsFromSelectedProducts,
        setCurrentFee: calculatorActions.setCurrentFee,
        setCurrentMonthsSelection: calculatorActions.setCurrentMonthsSelection,
        resetItems: calculatorActions.resetItems,
      },
      dispatch
    ), [dispatch]
  )

  const addItemToSelectedProducts = (productDetailView: IWoocommerceProduct) => {
    actions.addItemToSelectedProducts(productDetailView);
  }

  const selectedProductsWithVariations: IWoocommerceProduct[] = useAppSelector(state => calculatorSelectors.selectedProductsWithVariations(state));
  const removeItemFromSelectedProducts = (productId: number, selectionId: number) => {
    const selectedProduct = selectedProductsWithVariations.find(elem => elem.selectionId === selectionId)!;
    if(selectedProduct != null){
      actions.removeItemFromSelectedProducts(productId, selectionId);
    }
  }

  const removeAllItemsFromSelectedProducts = () => {
    actions.removeAllItemsFromSelectedProducts();
  }

  const updateItemInSelectedProducts = (productDetailView: IWoocommerceProduct) => {
    actions.updateItemInSelectedProducts(productDetailView);
  }

  const setCurrentFee = (fee: number) => actions.setCurrentFee(fee);
  const setCurrentMonthsSelection = (months: number|null) => actions.setCurrentMonthsSelection(months);
  const resetItems = () => actions.resetItems();

  return {
    addItemToSelectedProducts,
    removeItemFromSelectedProducts,
    removeAllItemsFromSelectedProducts,
    updateItemInSelectedProducts,
    setCurrentFee,
    setCurrentMonthsSelection,
    resetItems,
  }
}



const useCalculator = () => {
  const selectedProductsWithVariations: IWoocommerceProduct[] = useAppSelector(state => calculatorSelectors.selectedProductsWithVariations(state));
  const quiverFeeMonthly: number = useAppSelector(state => calculatorSelectors.currentFee(state));
  const quiverMonthSelection = useAppSelector(state => calculatorSelectors.currentMonthsSelection(state));

  // quiverPriceMonthly is the sum of monthly prices of all selected items
  const quiverTotalEquipmentMonthly = useMemo(() => {
    if(selectedProductsWithVariations.length >= 1){
      let monthlyPrice = 0;
      selectedProductsWithVariations.forEach(product => {
        monthlyPrice += (product.selectedProductVariation != null ? Number(product.selectedProductVariation.price) : 0);
      });
      return monthlyPrice;
    } else {
      return 0;
    }
  }, [selectedProductsWithVariations])

  // quiverTotalMonthly is the monthly payable price for the equipment incl. fee (dependent on the duration)
  const quiverTotalMonthly = useMemo(() => {
    if(quiverTotalEquipmentMonthly === 0)
      return quiverTotalEquipmentMonthly;
    else
      return quiverTotalEquipmentMonthly + quiverFeeMonthly;
  }, [quiverTotalEquipmentMonthly, quiverFeeMonthly])

  // quiverRegularPrice is an indication of how much the package would be normally worth in total
  const quiverRegularPrice = useMemo(() => {
    let regularPrice = 0;
    selectedProductsWithVariations.forEach(product => {
      regularPrice += (product.selectedProductVariation != null ? Number(product.selectedProductVariation.regularPrice) : 0);
    });
    return regularPrice;
  // eslint-disable-next-line
  }, [selectedProductsWithVariations]);

  return {
    selectedProductsWithVariations,
    quiverTotalEquipmentMonthly,
    quiverMonthSelection,
    quiverRegularPrice,
    quiverTotalMonthly,
    quiverFeeMonthly,
  }
}

export { useCalculatorActions, useCalculator };