import React, { useEffect } from 'react';
import { Buffer } from 'buffer';
import { useCalculatorActions } from './hooks/calculator';
import { KiteQuiver } from './screens/kite-quiver/kite-quiver';
import { WelcomeScreen, InfoChooseGear, InfoSetDuration, InfoEnjoyKiting, ChooseType, SuccessScreen, ProductDetailView, ChooseProduct, ContactDetails } from 'screens';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Router from './router';
import TagManager from 'react-gtm-module';
import './styles/globals.scss';


function App() {

  const { resetItems } = useCalculatorActions();

  const paths = {
    welcomeScreen: "/",
    howItWorks: "/howItWorks1",
    infoChooseGear: "/howItWorks1",
    infoSetDuration: "/howItWorks2",
    infoEnjoyKiting: "/howItWorks3",
    chooseType: "/chooseType",
    chooseProduct: "/chooseProduct",
    shoppingCart: "/shoppingCart",
    productDetailView: "/productDetails",
    contactDetails: "/contactDetails",
    success: "/success"
  }

  const routes = [
    { path: paths.welcomeScreen , component: <WelcomeScreen />},
    { path: paths.howItWorks, component: <InfoChooseGear />},
    { path: paths.infoChooseGear, component: <InfoChooseGear /> },
    { path: paths.infoSetDuration, component: <InfoSetDuration /> },
    { path: paths.infoEnjoyKiting, component: <InfoEnjoyKiting /> },
    { path: paths.chooseType, component: <ChooseType /> },
    { path: paths.chooseProduct, component: <ChooseProduct /> },
    { path: paths.shoppingCart, component: <KiteQuiver/> },
    { path: paths.productDetailView, component: <ProductDetailView /> },
    { path: paths.contactDetails, component: <ContactDetails /> },
    { path: paths.success, component: <SuccessScreen /> }
  ];

  const THEME = createTheme({
    typography: {
     "fontFamily": `var(--font-montserrat), sans-serif`,
     "fontSize": 14,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
    },
    palette: {
      primary: {
        main: '#4ce3de', // SaltyLemon turquoise
          // '#1976d2' MUI blue
      },
      secondary: {
        main: '#f9ff00', // SaltyLemon yellow
      },
    },
  });
  
  window.Buffer = Buffer

  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      resetItems()
    });
  // eslint-disable-next-line
  }, []);

  // Initialize Google Tag Manager with your container ID
  TagManager.initialize({ gtmId: 'GTM-TNL65QF' });

  return (
    <div
      id="salty-lemon-calculator"
      className={"container"}
    >
      <ThemeProvider theme={THEME}>
        <Router routes={routes} />
      </ThemeProvider>
    </div>
  );
}

export default App
