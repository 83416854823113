import { IWoocommerceProduct } from 'interfaces'
import { AppDispatch } from 'store'
import * as actionTypes from './actionTypes'



function addItemToSelectedProducts(chosenItem: IWoocommerceProduct) {
  return (dispatch: AppDispatch) => 
    dispatch({
      type: actionTypes.ADD_TO_PRODUCT_SELECTION,
      payload: chosenItem,
    })
}

function updateItemInSelectedProducts(chosenItem: IWoocommerceProduct) {
  return (dispatch: AppDispatch) => 
    dispatch({
      type: actionTypes.UPDATE_PRODUCT_SELECTION,
      payload: chosenItem
    })
}

function removeItemFromSelectedProducts(productId: number, selectionId: number) {
  return (dispatch: AppDispatch) => 
    dispatch({
      type: actionTypes.REMOVE_PRODUCT_FROM_SELECTION,
      payload: { productId: productId, selectionId: selectionId }
    })
}

function removeAllItemsFromSelectedProducts() {
  return (dispatch: AppDispatch) => 
    dispatch({
      type: actionTypes.REMOVE_ALL_PRODUCTS_FROM_SELECTION,
      payload: {}
    })
}

function setCurrentFee(fee: number) {
  return (dispatch: AppDispatch) => 
    dispatch({
      type: actionTypes.SET_CURRENT_FEE,
      payload: fee 
    })
}

function setCurrentMonthsSelection(months: number|null) {
  return (dispatch: AppDispatch) => 
    dispatch({
      type: actionTypes.SET_CURRENT_MONTHS_SELECTION,
      payload: months
    })
}

function resetItems() {
  return (dispatch: AppDispatch) => 
    dispatch({ type: actionTypes.RESET_ITEMS})
}

export {
  addItemToSelectedProducts,
  removeItemFromSelectedProducts,
  removeAllItemsFromSelectedProducts,
  updateItemInSelectedProducts,
  setCurrentFee,
  setCurrentMonthsSelection,
  resetItems,
}