import { IWoocommerceProduct, ProductType } from 'interfaces';
import React, { useCallback } from 'react';
import { useCalculator, useCalculatorActions } from '../hooks/calculator';
import generateClass from '../utils/generateClass';
import { Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import style from './product-carousel.module.scss';
const stylePrefix = 'product-carousel-';


export const ProductCarousel = () => {
  const { selectedProductsWithVariations } = useCalculator();
  const { removeItemFromSelectedProducts } = useCalculatorActions();
  const navigate = useNavigate();
  const handleNavigation = useCallback((pathname: string, state: any) => navigate(pathname, { state }), [navigate]);


  interface SelectedItemCardProps {
    product: IWoocommerceProduct,
    handleClickEvent: () => void
  }
  const SelectedItemCard: React.FC<SelectedItemCardProps> = useCallback(({product, handleClickEvent}) => {
    var foundImage = product.images.find(img => img.id === product.selectedProductVariation.image.id);
    
    return (
      <div 
        key={'item-selection-id-' + product.selectionId.toString()}
        className={generateClass(style, stylePrefix, '-item-container')}
      >
        <div className={generateClass(style, stylePrefix, '-item-content')}
             onClick={handleClickEvent} >
          { foundImage != null ? <img src={product.images[0].src} alt={'Card Item'}/> : <CircularProgress />} 
          
          <div className={generateClass(style, stylePrefix, '-item-name-wrapper')}>
            <p className={generateClass(style, stylePrefix, '-item-name-text')}>
              {product.name}
            </p>
          </div>
          
          <div className={generateClass(style, stylePrefix, '-item-size-and-price-wrapper')}>
            <div className={generateClass(style, stylePrefix, '-item-size-wrapper')}>
              <p>{product.selectedSize != null ? product.selectedSize.replace('m', 'm²') : ''}</p>
            </div>
            <div className={generateClass(style, stylePrefix, '-item-price-wrapper')}>
              <p>{Number(product.selectedProductVariation.price)}€<span>/m</span></p>
            </div>
          </div>
        </div>

        <Button variant="outlined" startIcon={<DeleteIcon />} color={"error"} sx={{marginTop: '10px'}} onClick={() => {
          removeItemFromSelectedProducts(product.id, product.selectionId)
        }}>
          Delete
        </Button>
      </div>
  // eslint-disable-next-line
  )}, []);

  interface EmptyItemCardProps {
    product: IWoocommerceProduct
  }
  const EmptyItemCard: React.FC<EmptyItemCardProps> = useCallback(({product}) => (
    <div 
      key={'empty-item-selection-id-' + product.selectionId.toString()}
      className={generateClass(style, stylePrefix, '-empty-item-container')}
    >
      <div className={generateClass(style, stylePrefix, '-empty-item-content')}>
        <div className={generateClass(style, stylePrefix, '-empty-item-content-above')}>
          <CircularProgress />
        </div>
        
        <div className={generateClass(style, stylePrefix, '-empty-item-content-middle-row-wrapper')}>
          <p className={generateClass(style, stylePrefix, '-empty-item-content-middle-row-text')}>
            --
          </p>
        </div>
        
        <div className={generateClass(style, stylePrefix, '-empty-item-size-and-price-wrapper')}>
          <div className={generateClass(style, stylePrefix, '-empty-item-size-wrapper')}>
            <p>--</p>
          </div>
          <div className={generateClass(style, stylePrefix, '-empty-item-price-wrapper')}>
            <p>--€<span>/m</span></p>
          </div>
        </div>
      </div>
      
      <div style={{height: '42px'}}></div>
    </div>
  ), []);
  
  const AddItemCard = () => (
    <div key={'add-item-card'} onClick={() => handleNavigation("/chooseType", {})} className={generateClass(style, stylePrefix, '-add-item-container')}>
      <div className={generateClass(style, stylePrefix, '-add-item-content')}>
        {/* Plus Sign -> '+' */}
        <p>&#43;</p>
      </div>
      <div style={{height: '42px'}}></div>
    </div>
  )


  const renderProductsForType = useCallback((items: IWoocommerceProduct[], productType: ProductType) => {
    const productItemsByType = items.filter(item => item.productType === productType);

    return (
      productItemsByType.map((item: IWoocommerceProduct, i: number) => {
        if(item == null)
          return <></>;

        return (item.selectedProductVariation == null || item.selectedProductVariation.image == null) ?         
          <EmptyItemCard product={item} />
          : (
          <SelectedItemCard
            product={item}
            handleClickEvent={() => {
              navigate('/productDetails', { state: { context: { productDetailView: item, from: 'shoppingCart' }}});
            }}
          />
        )
      })
  // eslint-disable-next-line
  )}, [selectedProductsWithVariations]);


  return (
    <div className={generateClass(style, stylePrefix, '-container')}>    
      <div
        id='product-carousel-scroller'
        className={generateClass(style, stylePrefix, '-swipable-row-container')}
      >
        { renderProductsForType(selectedProductsWithVariations, ProductType.Kite) }
        { renderProductsForType(selectedProductsWithVariations, ProductType.Bar) }
        { renderProductsForType(selectedProductsWithVariations, ProductType.Board) }
        <AddItemCard />
      </div>
    </div>
  )
};