import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Radio, RadioGroup, FormControlLabel, FormControl, Card, CardContent, Typography, Stack, Divider, CardHeader, Collapse, IconButton } from '@mui/material';
import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from 'emailjs-com';
import styles from "./contact-details.module.scss";
import { useCalculator } from "hooks";
import { ProductType } from "interfaces";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const arrowBackIcon = require("../../assets/icons/arrow-back.svg").default;
const rocketIcon = require("../../assets/icons/rocket.svg").default;


enum ContactMethod {
    Email = "email",
    WhatsApp = "whatsapp",
    Meeting = "meeting",
}

type HookFormInputs = {
    contactMethod: ContactMethod;
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string
};

type EmailPayloadValues = {
    to_email: string, // optional paramater as it is defined in EmailJS
    subject: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string,
    preferredContact: string,
    selectedProducts: {
        productNames: string,
        productTypes: string,
        prices: string,
        colors: string,
        sizes: string,
        lineLengths: string,
    },
    totalEquipmentPrice: number,
    selectedMonths: number,
    totalFee: number,
    totalMonthlyPrice: number
}

export const ContactDetails: FunctionComponent = () => {
    const navigate = useNavigate();
    const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
    
    const { register, handleSubmit, formState: { errors } } = useForm<HookFormInputs>();
    const {
        selectedProductsWithVariations,
        quiverMonthSelection,
        quiverTotalEquipmentMonthly,
        quiverTotalMonthly,
        quiverFeeMonthly,
      } = useCalculator();

    const [contactChoice, setContactChoice] = useState<string>(ContactMethod.WhatsApp);
    const [emailPayload] = useState<EmailPayloadValues>({
        to_email: 'sales@saltylemonkites.com',
        subject: 'Reqeust @SaltyLemon Subscription Configurator',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        preferredContact: '',
        email: '',
        selectedProducts: {
            productNames : '',
            productTypes: '',
            prices: '',
            colors: '',
            sizes: '',
            lineLengths: ''
        },
        totalEquipmentPrice: quiverTotalEquipmentMonthly,
        selectedMonths: quiverMonthSelection,
        totalFee: quiverFeeMonthly,
        totalMonthlyPrice: quiverTotalMonthly
    });

    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContactChoice((event.target as HTMLInputElement).value);
    };

    const onSubmit: SubmitHandler<HookFormInputs> = (data) => {
        emailPayload.firstName = data.firstName;
        emailPayload.lastName = data.lastName;
        emailPayload.phoneNumber = data.phoneNumber;
        emailPayload.email = data.email;
        emailPayload.preferredContact = contactChoice;
        emailPayload.selectedProducts = {
            productNames : '',
            productTypes: '',
            prices: '',
            colors: '',
            sizes: '',
            lineLengths: ''
        };

        selectedProductsWithVariations.forEach(product => {
            emailPayload.selectedProducts.productNames = emailPayload.selectedProducts.productNames + (product.name ?? '') + "<br />";
            emailPayload.selectedProducts.productTypes = emailPayload.selectedProducts.productTypes + (product.productType != null ? product.productType.toString() : '') + "<br />";
            emailPayload.selectedProducts.prices = emailPayload.selectedProducts.prices + (product.selectedProductVariation.price != null ? product.selectedProductVariation.price + ' €' : '') + "<br />";
            emailPayload.selectedProducts.colors = emailPayload.selectedProducts.colors + (product.selectedColor != null ? product.selectedColor : '') + "<br />";
            emailPayload.selectedProducts.sizes = emailPayload.selectedProducts.sizes + (product.selectedSize ?? '') + "<br />";
            product.productType === ProductType.Bar ?
                emailPayload.selectedProducts.lineLengths = emailPayload.selectedProducts.lineLengths + product.selectedLineLength + "<br />" :
                emailPayload.selectedProducts.lineLengths += "<br />"
        });


        // ****************************************
        // *** EmailJS: Salty Lemon -> INTERNAL ***
        // ****************************************
        if (process.env.NODE_ENV === 'development'){
            emailPayload.to_email = data.email; // send email to inserted email on website
        }
        emailjs.send(
            "service_Calculator",
            "template_pu477uq",
            emailPayload,
            "OhiDe9QrWZ77CSj1P"
        )
        .catch((error) => {
            console.log(error.text)
        });
        
        // ****************************************
        // *** EmailJS: Salty Lemon -> CUSTOMER ***
        // ****************************************
        emailPayload.to_email = data.email;
        emailjs.send(
            "service_Calculator",
            "template_2urjf47",
            emailPayload,
            "OhiDe9QrWZ77CSj1P"
        )
        .catch((error) => {
            console.log(error.text)
        });

        handleNavigation('/success', {context: {openHubspot: contactChoice === ContactMethod.Meeting ? true : false}});
    }

    const [showQuiverSummary, setShowQuiverSummary] = useState(true);
    const handleChangeShowQuiverSummary = () => {
    setShowQuiverSummary((prev) => !prev);
    };

    return (
        <>
            <img className={styles.arrowBackIcon} alt="" src={arrowBackIcon} onClick={() => {handleNavigation('/shoppingCart', {})}} />
            <div className={styles.contactDetailsContainer}>
                <div className={styles.bannerSection}>
                    <div className={styles.base}>
                        <div className={styles.greatChoice}>Great choice!</div>
                        <div className={styles.toEnsureTheHighestQuality}>
                            To ensure the highest quality service we will reach out in person and
                            confirm your subscription.
                        </div>
                    </div>
                </div>

                <Card sx={{ padding: 1, margin: '16px', boxShadow: 3, cursor: 'pointer' }} onClick={handleChangeShowQuiverSummary}>
                    <CardHeader 
                        title="Kite quiver summary"
                        sx={{ "& .MuiCardHeader-title": { fontWeight: "bold" }, padding: 1 }}
                        action={
                        <IconButton aria-label="settings">
                            { showQuiverSummary ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                        </IconButton>
                        } />

                    <Collapse in={showQuiverSummary}>
                        <CardContent>
                            <Stack spacing={2}>
                                {selectedProductsWithVariations.map((item, index) => (
                                    <Stack key={index} direction="row" justifyContent="space-between">
                                        <Typography>{item.name} {item.selectedSize != null ? <>- {item.selectedSize}</> : <></>}</Typography>
                                        <Typography>€ {item.selectedProductVariation.price}</Typography>
                                    </Stack>
                                ))}
                                <Divider />
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography>+ Duration fee</Typography>
                                    <Typography>€ {quiverFeeMonthly}</Typography>
                                </Stack>
                                <Divider />
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="h6">Total monthly ({quiverMonthSelection} months):</Typography>
                                    <Typography variant="h6" color="primary">€ {quiverTotalMonthly}</Typography>
                                </Stack>
                            </Stack>
                            <div className={styles.durationFee}>(incl. duration fee & VAT)</div>
                        </CardContent>
                    </Collapse>
                </Card>

                <div>
                    <div className={styles.contactMethodSection}>
                        <div className={styles.howDoYouWantUsToContact}>
                            How do you want us to contact you?
                        </div>

                        <Stack direction="row" justifyContent="space-between">
                            <div></div>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="email"
                                    name="radio-buttons-group"
                                    className={styles.radioButtonContainer}
                                    value={contactChoice}
                                    onChange={handleRadioButtonChange}
                                >
                                    <FormControlLabel value={ContactMethod.WhatsApp} control={<Radio />} label="via Whatsapp" />
                                    <FormControlLabel value={ContactMethod.Email} control={<Radio />} label="via Email" />
                                    <FormControlLabel value={ContactMethod.Meeting} control={<Radio />} label="via Meeting/Call" />
                                </RadioGroup>
                            </FormControl>
                            <div></div>
                        </Stack>
                        
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.inputSection}>
                            <div className={styles.inputBoxContainer}>
                                <div className={styles.inputBoxHeader}>First name</div>
                                <input {...register("firstName", { required: true})} className={styles.inputBox} />
                                {errors.firstName?.type === 'required' && <p role="alert" style={{color: 'red'}}>First name is required!</p>}
                            </div>

                            <div className={styles.inputBoxContainer}>
                                <div className={styles.inputBoxHeader}>Last name</div>
                                <input {...register("lastName", { required: true})} className={styles.inputBox} />
                                {errors.lastName?.type === 'required' && <p role="alert" style={{color: 'red'}}>Last name is required!</p>}
                            </div>
                            
                            <div className={styles.inputBoxContainer}>
                                <div className={styles.inputBoxHeader}>Email</div>
                                { 
                                // eslint-disable-next-line no-useless-escape 
                                <input type="email" placeholder="myemail@example.com" {...register("email", { required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ })} className={styles.inputBox} />
                                }
                                {errors.email?.type === 'required' && <p role="alert" style={{color: 'red'}}>Email address is required!</p>}
                                {errors.email?.type === 'pattern' && <p role="alert" style={{color: 'red'}}>Incorrect email address format!</p>}
                            </div>

                            <div className={styles.inputBoxContainer}>
                                <div className={styles.inputBoxHeader}>Phone number</div>
                                <input placeholder="+49__________" {...register("phoneNumber", { required: (contactChoice === ContactMethod.WhatsApp), pattern: /^\+[1-9]{1}[0-9]{3,14}$/ })} className={styles.inputBox} />
                                {errors.phoneNumber?.type === 'required' && <p role="alert" style={{color: 'red'}}>Phone number is required!</p>}
                                {errors.phoneNumber?.type === 'pattern' && <p role="alert" style={{color: 'red'}}>Incorrect phone number format! Please remove all whitespaces and make sure the number starts with a valid international code. For example: +49</p>}
                            </div>
                        </div>

                        {selectedProductsWithVariations.length === 0 && 
                            <p role="alert" style={{color: 'red', textAlign: 'center'}}><br />No product was found!<br />To continue you need to select at least one product!</p>
                        }

                        {selectedProductsWithVariations.length > 0 && 
                            <button className={styles.button} onClick={handleSubmit(onSubmit)}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <img
                                        className={styles.rocketIcon}
                                        alt=""
                                        src={rocketIcon}
                                    />
                                    <div className={styles.submitButton}>Submit</div>
                                </div>
                            </button>
                        }
                    </form>
                </div>
            </div>
        </>
    );
};